import React from 'react';
import { graphql } from 'gatsby';
import { Section, Row, Container } from '@components/Layout';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import styled from 'styled-components';
import AniLink from 'gatsby-plugin-transition-link/AniLink';
import ParticleChecker from '@components/ParticleChecker';
import { down } from '@helper/breakpoint.helper';
import Parallax from '@components/Parallax';
import Helmet from 'react-helmet';
import theme from '@/theme';
// import 'pathseg'; // needed for development - displays the "wow" polygon


const StyledLogo = styled.img`
    right: 0;
    top: 30px;
    width: 50px;
    position: absolute;
    z-index: 10;
`;

const StyledStartText = styled.h1`
    width: 100%;
    text-align: center;
    color: #b9b9b9;
    font-family: 'Boston-Thin',sans-serife;

    margin-top: -200px;
    margin-bottom: 350px;
    font-size: 30px;
    
    ${down('md')}
{
    font-size: 20px;
}
`;


const StyledAccentImage = styled.img`
width: 100px;
position: absolute;
left: 50%;

top: -30px;
z-index: 10;
transform: translateX(-50%);

${down('md')}
{
    width: 100px;
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    top: -16px;
    z-index: 10;
}
`;

const StyledParallaxImage = styled.img`
width: 250px;
height: auto;
position: absolute;
left: 5%;
`;

const StyledContentImageContainer = styled.div`
height: 100%;
width: auto; 
overflow: hidden;
transition: all .3s ease;
  -webkit-filter: grayscale(100%); /* Safari 6.0 - 9.0 */
  filter: grayscale(100%);
  
${down('md')}
{
height: ${theme.components.imageContainer.height[0]};
}

&:hover{
  filter: grayscale(0);
  transform: scale(1.15);
}
`;

const StyledContentText = styled.span`
font-family: 'Boston-Thin', sans-serife;

    color: white;
    font-weight: bold;
    font-size: 27px;
    text-transform: uppercase;

    position: relative;
    
${down('md')}
{

font-size: 24px;
}
`;

const IndexRow = styled(Row)`
padding: 0 10vw 0 20vw; 
position: relative;
flex-direction: row;

&:nth-child( 4n ){
padding: 0 15vw 50px 20vw; 
}

${down('md')}
{

&:nth-child( odd ){
padding: ${theme.components.indexRow.padding[0]};
}

padding: ${theme.components.indexRow.padding[0]};
}

`;

const IndexPage = ({ data } : any) => {
  const filteredData = data.allSitePage.edges.filter((d: any) => !!d.node.context?.slug);

  return (

    <Section>

      <Helmet>
        <title>pixelart - WOW Shop</title>
        <meta name="wow shop" content="Eine Sammlung an experimentellen Projekten." />
        <html lang="de" />
      </Helmet>

      <Container>
        <StyledLogo className="" src="/pix_logo.svg" alt="" />
      </Container>

      <Parallax />
      <ParticleChecker />

      <StyledStartText className="rellax3">
        Wow-Shops - Ein Wahnsinn was hier abgeht
      </StyledStartText>

      <IndexRow className=" columns is-multiline">

        {filteredData.map(({ node } : any, index : any) => (

          <AniLink key={node.context.slug} paintDrip hex="#000" className={`floating${index === 1 || index === 3 || index === 5 || index === 7 ? ' rellax2' : ' rellax3'}`} to={node.context.slug}>
            <StyledAccentImage className="" src="/dash.svg" alt="" />
            <StyledContentImageContainer className="styled-content-image-container">

              <LazyLoadImage placeholderSrc="/patternio.jpg" className="content__image" effect="blur" src={node.context.thumbnailPath} alt="" />
            </StyledContentImageContainer>
            <StyledContentText className="content__item-text">
              {node.context.title}
            </StyledContentText>
          </AniLink>
        ))}

        <StyledParallaxImage className="rellax3" src="/pattern_round.png" alt="" />
      </IndexRow>
    </Section>
  );
};

export default IndexPage;
export const query = graphql`
query{
  allSitePage {
    edges {
      node {
        context {
          title
          slug
          thumbnailPath
        }
      }
    }
  }
}
`;
