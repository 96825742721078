import React from 'react';
import theme from '@/theme';
import Rellax from '../../../node_modules/rellax';


export default class Parallax extends React.Component {
  constructor(props) {
    super(props);
    this.state = { rellax2: null, rellax3: null };
    this.initParallax = this.initParallax.bind(this);
  }

  componentDidMount() {
    this.initParallax();
    window.addEventListener('resize', this.initParallax);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.initParallax);
  }

  initParallax() {
    if (window.innerWidth > theme.maxWidths[1]) {
      this.state.rellax2 = new Rellax('.rellax2', {
        center: false,
        speed: 5,
      });

      this.state.rellax3 = new Rellax('.rellax3', {
        center: false,
        speed: 1,
      });
    } else {
      const { rellax2, rellax3 } = this.state;
      if (rellax2) {
        rellax2.destroy();
      }
      if (rellax3) {
        rellax3.destroy();
      }
    }
  }

  render() {
    return null;
  }
}
