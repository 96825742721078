import React from 'react';
import Particles from 'react-particles-js';
import theme from '@/theme';


export default class ParticleChecker extends React.Component {
  constructor(props: any) {
    super(props);

    this.state = { isMobile: false };
    this.checkMobile = this.checkMobile.bind(this);
  }

  componentDidMount() {
    import('pathseg').then(() => {
      this.checkMobile();
      window.addEventListener('resize', this.checkMobile);
    });

  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.checkMobile);
  }

  checkMobile() {
    const mobileCheck = window.innerWidth < theme.maxWidths[2];
    this.setState({ isMobile: mobileCheck });
  }

  render() {
    const { isMobile } = this.state;
    let particles;

    if (isMobile) {
      particles = (
        <Particles
          canvasClassName="particles-canvas--mobile"
          className="particles-canvas-container rellax2"
          params={{
            fps_limit: 28,
            particles: {
              number: {
                value: 120,
                density: {
                  enable: false,
                },
              },

              line_linked: {
                enable: true,
                distance: 40,
                opacity: 0.4,
              },
              move: {
                speed: 1,
              },
              opacity: {
                anim: {
                  enable: true,
                  opacity_min: 0.05,
                  speed: 2,
                  sync: false,
                },
                value: 0.1,
              },
            },
            polygon: {
              enable: true,
              scale: 1,
              type: 'inline',
              move: {
                radius: 3,
              },
              url: '/deer.svg',
              inline: {
                arrangement: 'equidistant',
              },
              draw: {
                enable: true,
                stroke: {
                  color: 'rgba(255, 255, 255, .2)',
                },
              },
            },
            retina_detect: false,
            interactivity: {
              events: {
                onhover: {
                  enable: true,
                  mode: 'bubble',
                },
              },
              modes: {
                bubble: {
                  size: 20,
                  distance: 100,
                },
              },
            },
          }}
        />
      );
    } else {
      particles = (
        <Particles
          canvasClassName="particles-canvas"
          className="particles-canvas-container rellax2"
          params={{
            fps_limit: 28,
            particles: {
              number: {
                value: 170,
                density: {
                  enable: false,
                },
              },

              line_linked: {
                enable: true,
                distance: 150,
                opacity: 0.4,
              },
              move: {
                speed: 1,
              },
              opacity: {
                anim: {
                  enable: true,
                  opacity_min: 0.05,
                  speed: 2,
                  sync: false,
                },
                value: 0.1,
              },
            },
            polygon: {
              enable: true,
              scale: 4,
              type: 'inline',
              move: {
                radius: 10,
              },
              url: '/deer.svg',
              inline: {
                arrangement: 'equidistant',
              },
              draw: {
                enable: true,
                stroke: {
                  color: 'rgba(255, 255, 255, .2)',
                },
              },
            },
            retina_detect: false,
            interactivity: {
              events: {
                onhover: {
                  enable: true,
                  mode: 'bubble',
                },
              },
              modes: {
                bubble: {
                  size: 20,
                  distance: 100,
                },
              },
            },
          }}
        />
      );
    }

    return (
      particles
    );
  }
}
